/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CircleButton } from '../Buttons/Buttons'
import { baseFont } from '../../assets/styles/styles'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'
import { resetNativeInput } from '../../assets/styles/input'
import { iconHex } from '../../assets/styles/icons'
import { useCallback } from 'react'

const inputStyle = css`
  ${resetNativeInput};
  appearance: textfield;
  width: 30px;
  pointer-events: none;
  text-align: center;
  border: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`

const styleButton = css`
  margin: 2px;

  &::after {
    font-family: 'bonifont';
    color: ${colors.white};
  }
`

const minusButton = css`
  &::after {
    content: '\\${iconHex('minus')}';
  }
`

const plusButton = css`
  &::after {
    content: '\\${iconHex('plus')}';
  }
`

export function Counter ({ className, onChange, ...other }) {
  const handleChange = useCallback((newValue) => {
    if (onChange) {
      onChange(newValue)
    }
  }, [onChange])

  const onSubtractButtonClick = useCallback(() => {
    handleChange(Math.max(other.value - 1, other.min))
  }, [handleChange, other.min, other.value])

  const onAddButtonClick = useCallback(() => {
    handleChange(Math.min(other.value + 1, other.max))
  }, [handleChange, other.max, other.value])

  const onCounterInputChange = useCallback(({ target: { value } }) => {
    const newValue = Number(value)
    if (onChange) {
      onChange(newValue)
    }
  }, [onChange])

  return (
    <div css={[flexHorizontallyCentered, baseFont]}>
      <CircleButton
        css={[styleButton, minusButton]}
        onClick={onSubtractButtonClick}
      />

      <input
        data-testid='counter'
        type='number'
        css={inputStyle}
        className={className}
        onChange={onCounterInputChange}
        readOnly
        {...other}
      />

      <CircleButton
        css={[styleButton, plusButton]}
        onClick={onAddButtonClick}
      />
    </div>
  )
}

export const CounterHiddenValue = props => (
  <Counter
    {...props}
    css={css`
      display: none;
    `}
  />
)
